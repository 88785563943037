export default {
  IcoMoonType: 'selection',
  icons: [
    {
      icon: {
        paths: [
          'M952.902 643.672v191.439h-854.375v-191.439h-65.721v223.478c0 17.685 14.721 32.036 32.859 32.036h920.098c18.138 0 32.861-14.352 32.861-32.036v-223.478h-65.721zM746.207 427.915c-12.946-12.623-33.914-12.623-46.925 0l-140.709 160.123v-478.324h-65.72v478.324l-140.775-160.123c-12.948-12.623-33.914-12.623-46.925 0-12.666 12.349-12.941 32.238-0.826 44.915 0.269 0.281 0.544 0.559 0.826 0.834l197.033 224.008c6.506 6.343 14.982 9.484 23.528 9.484 8.544 0 17.020-3.141 23.526-9.42l196.967-224.072c12.948-12.623 12.948-33.126 0-45.749z',
        ],
        isMulticolor: false,
        isMulticolor2: false,
        tags: ['download'],
        defaultCode: 59394,
        grid: 0,
        attrs: [],
      },
      attrs: [],
      properties: {
        id: 28,
        order: 1027,
        ligatures: '',
        prevSize: 32,
        code: 59394,
        name: 'download',
      },
      setIdx: 1,
      setId: 3,
      iconIdx: 27,
    },
    {
      icon: {
        paths: [
          'M512 0c-282.667 0-512 229.333-512 512s229.333 512 512 512 512-229.333 512-512c-0.213-282.667-229.333-511.787-512-512zM512 989.867c-263.893 0-477.867-213.973-477.867-477.867s213.973-477.867 477.867-477.867 477.867 213.973 477.867 477.867c-0.213 263.893-213.973 477.653-477.867 477.867z',
          'M420.267 276.907c-5.12-4.053-12.16-4.907-18.133-2.133s-9.813 8.747-9.813 15.36v443.733c0 6.613 3.84 12.587 9.813 15.36s13.013 1.92 18.133-2.133l273.067-221.867c4.053-3.2 6.4-8.107 6.4-13.227s-2.347-10.027-6.4-13.227l-273.067-221.867zM426.667 698.027v-372.053l228.907 186.027-228.907 186.027z',
        ],
        attrs: [
          {
            fill: 'rgb(255, 255, 255)',
          },
          {
            fill: 'rgb(255, 255, 255)',
          },
        ],
        isMulticolor: false,
        isMulticolor2: false,
        grid: 0,
        tags: ['play mobile'],
      },
      attrs: [
        {
          fill: 'rgb(255, 255, 255)',
        },
        {
          fill: 'rgb(255, 255, 255)',
        },
      ],
      properties: {
        order: 19,
        id: 0,
        name: 'play-mobile',
        prevSize: 32,
      },
      setIdx: 0,
      setId: 5,
      iconIdx: 0,
    },
    {
      icon: {
        paths: [
          'M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM512 928c-229.75 0-416-186.25-416-416s186.25-416 416-416 416 186.25 416 416-186.25 416-416 416zM384 288l384 224-384 224z',
        ],
        tags: ['play', 'player'],
        defaultCode: 59925,
        grid: 16,
        attrs: [],
      },
      attrs: [],
      properties: {
        ligatures: 'play2, player',
        name: 'play2',
        order: 13,
        id: 278,
        prevSize: 32,
        code: 59925,
      },
      setIdx: 8,
      setId: 1,
      iconIdx: 277,
    },
  ],
  height: 1024,
  metadata: {
    name: 'icomoon',
  },
  preferences: {
    showGlyphs: true,
    showQuickUse: true,
    showQuickUse2: true,
    showSVGs: true,
    fontPref: {
      prefix: 'icon-',
      metadata: {
        fontFamily: 'icomoon',
        majorVersion: 1,
        minorVersion: 0,
      },
      metrics: {
        emSize: 1024,
        baseline: 6.25,
        whitespace: 50,
      },
      embed: false,
    },
    imagePref: {
      prefix: 'icon-',
      png: true,
      useClassSelector: true,
      color: 0,
      bgColor: 16777215,
      classSelector: '.icon',
      name: 'icomoon',
      height: 32,
      columns: 16,
      margin: 16,
      addBgColor: false,
      sprite: false,
    },
    historySize: 50,
    showCodes: false,
    gridSize: 16,
    showLiga: false,
  },
};
