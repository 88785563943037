 

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import H2 from '../src/components/Titles/TitleH2/TitleH2';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Layout from '../src/components/Layout/Layout';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import SEO from '../src/helpers/seo';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Ymir from '../src/components/Ymir/Ymir';
import tt from '../src/helpers/translation';
import Icon from '../src/helpers/icon';
import Hod from '../src/components/Hod/Hod';
import condicionesIcons from '../src/icons/condiciones-generales-icons';
import './condiciones_generales.scss';
import portaventuraES from '../src/documents/portaventura_ES.pdf';
import portaventuraCA from '../src/documents/portaventura_CA.pdf';
import portaventuraEN from '../src/documents/portaventura_EN.pdf';
import portaventuraDE from '../src/documents/portaventura_DE.pdf';
import portaventuraRU from '../src/documents/portaventura_RU.pdf';
import portaventuraIT from '../src/documents/portaventura_IT.pdf';
import portaventuraFR from '../src/documents/portaventura_FR.pdf';

export const pageQuery = ({ data, pathContext: { locale } }) => graphql`
  query condiciones_generales($locale: String!) {
    allConditionTitleHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allConditionImageHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allConditionSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allConditionMainTextBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          body
        }
      }
    }
    allConditionRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class CondicionesGenerales extends Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allConditionTitleHeader.edges[0].node.title,
      image: {
        url: this.props.data.allConditionImageHeader.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allConditionImageHeader.edges[0].node.alt,
      },
    };

    const ymirData = {
      cards: [
        {
          title: tt('HORARIOS Y CALENDARIO', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/horarios-calendario', this.props.pageContext.locale),
          icon: 'calendar',
          titleType: 'h3',
        },
        {
          title: tt('CÓMO LLEGAR AL RESORT', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/destino/planificar/como-llegar-a-portaventura', this.props.pageContext.locale),
          icon: 'LOCATION',
          titleType: 'h3',
        },
        {
          title: tt('DESCUENTOS EN ENTRADAS ONLINE', this.props.pageContext.locale),
          buttonCard: tt('DESCUBRIR', this.props.pageContext.locale),
          link: tt('/promociones', this.props.pageContext.locale),
          icon: 'Methodofpayment',
          titleType: 'h3',
        },
      ],
    };

    let pdf;
    switch (this.props.pageContext.locale) {
      case 'es':
        pdf = portaventuraES;
        break;
      case 'fr':
        pdf = portaventuraFR;
        break;
      case 'en':
        pdf = portaventuraEN;
        break;
      case 'de':
        pdf = portaventuraDE;
        break;
      case 'ru':
        pdf = portaventuraRU;
        break;
      case 'it':
        pdf = portaventuraIT;
        break;
      case 'ca':
        pdf = portaventuraCA;
        break;
      default:
        pdf = portaventuraES;
        break;
    }

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allConditionSeoData.edges[0].node._0.title}
          description={this.props.data.allConditionSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allConditionRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allConditionImageHeader.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="condiciones-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
              />
              <BlueArrow />
              <H2 data={this.props.data.allConditionMainTextBlock.edges[0].node} />
              <div className="download-icon">
                <a
                  href={pdf}
                  download={`portaventura_${this.props.pageContext.locale.toUpperCase()}`}
                >
                  <Icon
                    icon="download"
                    width="30"
                    height="30"
                    class="download-icon"
                    iconSet={condicionesIcons}
                  />
                </a>
              </div>
              <div
                className="inner-text"
                dangerouslySetInnerHTML={{
                  __html: this.props.data.allConditionMainTextBlock.edges[0].node.body,
                }}
              />
              <Ymir data={ymirData} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
CondicionesGenerales.propTypes = {
  ourShopsData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default CondicionesGenerales;
